import Image from "next/image";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { ButtonType, Size } from "@/types/types";
import { Spinner } from "../spinner/Spinner";
import Icon from "../icon/Icon";
import { IconNames } from "@/types/iconNames";

export type ButtonProps = {
  buttonText: string;
  iconName?: IconNames;
  iconColor?: string;
  iconWidth?: number;
  iconHeight?: number;
  disabled?: boolean;
  onClick: (e: any) => void;
  visibleSpinner?: boolean;
  customClassName?: string;
  size?: Size;
  type?: ButtonType;
  ariaLabel: string;
};

export const Button = ({
  buttonText,
  iconName,
  iconColor,
  iconWidth,
  iconHeight,
  disabled,
  onClick,
  visibleSpinner,
  customClassName,
  size = Size.MEDIUM,
  type = ButtonType.PRIMARY_DARK,
  ariaLabel,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      className={classNames(
        customClassName,
        styles.btn,
        styles[size],
        styles[type]
      )}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {!visibleSpinner && (
        <>
          {iconName && (
            <p className={styles.icon}>
              <Icon
                iconName={iconName}
                color={iconColor || "var(--color-primary)"}
                width={iconWidth}
                height={iconHeight}
              />
            </p>
          )}
          {buttonText}
        </>
      )}
      {visibleSpinner && <Spinner size={"small"} />}
    </button>
  );
};
