import styles from "./Clipboard.module.scss";
import Icon from "@/icon/Icon";
import { Popover } from "@/components/popover/Popover";
import { IconNames } from "@/types/iconNames";

export const Clipboard = ({ text }: { text: string }) => {
  return (
    <Popover content="Copied to Clipboard" side="right">
      <button
        type="button"
        className={styles.clipboardIcon}
        onClick={() => navigator.clipboard.writeText(text)}
        aria-label="Copy action"
      >
        <Icon iconName={IconNames.COPY} color={"#03053D"} />
      </button>
    </Popover>
  );
};
