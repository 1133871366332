import styles from "./Footer.module.scss";
import Image from "next/image";
import { labels } from "@/utils/labels";
import Link from "next/link";
import { memo } from "react";

export const Footer = memo(function Footer() {
  enum FooterUrl {
    TERM_OF_USE = "https://www.st.com/content/st_com_cx/en/common/terms-of-use.html",
    SALES_TERM_CONDITION = "https://www.st.com/content/ccc/resource/legal/legal_agreement/sales_agreement/49/05/8c/68/a0/35/4a/6e/ST%20Terms%20and%20Conditions%20of%20Sales.pdf/files/ST%20Terms%20and%20Conditions%20of%20Sales.pdf/jcr:content/translations/en.ST%20Terms%20and%20Conditions%20of%20Sales.pdf",
    TRADEMARKS = "https://www.st.com/content/st_com_cx/en/about/st_company_information/stmicroelectronics-trademark-list.html",
    PRIVACY = "https://www.st.com/content/st_com_cx/en/common/privacy-portal.html",
    COOKIES = "https://www.st.com/content/st_com/en/common/privacy-portal/st-use-of-cookie.html",
  }
  return (
    <footer className={styles.footer}>
      <span className={styles.logoContainer}>
        <Image
          src={`${process.env.NEXT_PUBLIC_API_BASE_URL}/assets/Logo_small.svg`}
          alt="STMicroelectronics logo compact"
          width={110}
          height={77}
          loader={() =>
            `${process.env.NEXT_PUBLIC_API_BASE_URL}/assets/Logo_small.svg?w=110`
          }
        />
      </span>
      <section className={styles.footerLinks}>
        <span className={styles.text}>{labels.FOOTER_RIGHTS}</span>
        <ul>
          <li>
            <Link
              className={styles.link}
              href={FooterUrl.TERM_OF_USE}
              target="_blank"
            >
              Terms of use |
            </Link>
          </li>
          <li>
            <a
              className={styles.link}
              href={FooterUrl.SALES_TERM_CONDITION}
              target="_blank"
            >
              Sales terms & conditions |
            </a>
          </li>
          <li>
            <Link
              className={styles.link}
              href={FooterUrl.TRADEMARKS}
              target="_blank"
            >
              Trademarks |
            </Link>
          </li>
          <li>
            <Link
              className={styles.link}
              href={FooterUrl.PRIVACY}
              target="_blank"
            >
              Privacy portal |
            </Link>
          </li>
          <li>
            <Link
              className={styles.link}
              href={FooterUrl.COOKIES}
              target="_blank"
            >
              Manage cookies
            </Link>
          </li>
        </ul>
      </section>
    </footer>
  );
});
