import { useRef, useState } from "react";
import styles from "./Login.module.scss";
import classnames from "classnames";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@/icon/Icon";
import { useModalToggle } from "@/components/modal/Modal";
import {
  resetAllLogin,
  setBearerToken,
  setIsLoggedIn,
} from "@/features/login/loginSlice";
import { cleanAllStates, storeRefreshToken } from "@/utils/function";
import useOutsideAlerter from "@/hooks/useClickOutside";
import { IconNames } from "@/types/iconNames";
import { SubscriptionKeys } from "@/components/subscriptionKeys/SubscriptionKeys";
import { getIsLoggedIn, getUser } from "@/utils/selectors";
import { cognitoUrlLogin, cognitoUrlLogout } from "@/utils/constant";
import { resetAllDownload } from "@/features/download/downloadSlice";
import { setUrlParams } from "@/features/path/pathSlice";
import { Route } from "@/types/types";
import Link from "next/link";
import { labels } from "@/utils/labels";

export type LoginProps = {};

export const Login = ({}: LoginProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const router = useRouter();
  const dispatch = useDispatch();

  const [value, toggle] = useModalToggle(false);

  const handleClick = async () => {
    if (isLoggedIn) {
      router.push(cognitoUrlLogout).then(() => {
        storeRefreshToken();
        dispatch(setIsLoggedIn(false));
        dispatch(resetAllLogin());
        dispatch(resetAllDownload());
        dispatch(resetAllTraceId());
        cleanAllStates(dispatch);
        dispatch(setBearerToken(""));
        dispatch(setUrlParams({}));
      });
    } else {
      router.push(cognitoUrlLogin);
    }
  };

  useOutsideAlerter({
    ref: containerRef,
    onClickOutside: () => setIsOpen(false),
  });

  const SubscriptionKeysMenuItem = () => {
    return (
      <button
        type="button"
        className={styles.logBtn}
        onClick={() => {
          setIsOpen(false);
          toggle();
        }}
        aria-label="Subscription key access action"
      >
        <span className={styles.logText}>{labels.SUBSCRIPTION_KEY}</span>
        <span className={styles.logIcon}>
          <Icon iconName={IconNames.KEY} color={"var(--color-neutral-10)"} />
        </span>
      </button>
    );
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <button
        className={styles.userBtn}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        aria-label={isLoggedIn ? "Logout button" : "Login button"}
      >
        <p className={styles.iconContainer}>
          <Icon
            iconName={isLoggedIn ? IconNames.LOGOUT : IconNames.LOGIN}
            color={"var(--color-neutral-01)"}
            width={24}
            height={24}
          />
        </p>

        {isLoggedIn && <span className={styles.name}>{user}</span>}
      </button>

      {isOpen && (
        <div
          className={classnames(styles.logContainer, {
            [styles.open]: isOpen,
            [styles.login]: user,
          })}
        >
          <button
            type="button"
            className={styles.logBtn}
            onClick={() => {
              handleClick();
            }}
            aria-label={isLoggedIn ? "Logout action" : "Login action"}
          >
            <span className={styles.logText}>
              {isLoggedIn ? "Log out" : "Log in"}
            </span>
            <span className={styles.logIcon}>
              <Icon
                iconName={IconNames.EXIT}
                color={"var(--color-neutral-10)"}
              />
            </span>
          </button>
          {isLoggedIn && <SubscriptionKeysMenuItem />}
          {isLoggedIn && (
            <Link className={styles.logBtn} href={Route.HISTORY}>
              {labels.HISTORY}
              <span className={styles.logIcon}>
                <Icon
                  iconName={IconNames.HISTORY}
                  color={"var(--color-neutral-10)"}
                />
              </span>
            </Link>
          )}
        </div>
      )}

      {isLoggedIn && value && (
        <SubscriptionKeys statusModal={value} toggle={toggle} />
      )}
    </div>
  );
};
function resetAllTraceId(): any {
  throw new Error("Function not implemented.");
}
