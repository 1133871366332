import styles from "./SubscriptionKeys.module.scss";
import {
  DynamicTable,
  TableColumn,
} from "@/components/dynamicTable/DynamicTable";
import { BinIcon } from "@/components/binIcon/BinIcon";
import { SubscriptionKeysItem } from "@/types/subscriptionKeys";
import { labels } from "@/utils/labels";
import { Button } from "@/components/button/Button";
import { ApiError, ButtonType } from "@/types/types";
import { Spinner } from "@/components/spinner/Spinner";
import { ErrorBanner } from "@/components/errorBanner/ErrorBanner";
import { Modal } from "@/components/modal/Modal";
import {
  useGetSubscriptionKeysListQuery,
  useSubscriptionKeysDeleteMutation,
  useSubscriptionKeysGenerateMutation,
} from "@/pages/api/subscriptionKeys";

type SubscriptionKeysType = {
  statusModal: boolean;
  toggle: () => void;
};

export const SubscriptionKeys = ({
  statusModal,
  toggle,
}: SubscriptionKeysType) => {
  const {
    data: dataList,
    isFetching: isFetchingList,
    isLoading: isLoadingList,
    error,
  } = useGetSubscriptionKeysListQuery();
  const [createKey, { isLoading: isLoadingGenerate }] =
    useSubscriptionKeysGenerateMutation();
  const [removeSubscriptionKey, { isLoading: isLoadingDelete }] =
    useSubscriptionKeysDeleteMutation();

  const columns: TableColumn[] = [
    {
      type: "column",
      columnName: "SubscriptionKey",
      name: "Code",
      isClipboard: true,
    },
    {
      type: "column",
      columnName: "CreatedDate",
      name: "Generation Date",
      isDate: true,
    },
    { type: "column", columnName: "State", name: "Status" },
    {
      type: "action",
      name: "",
      element: <BinIcon />,
      action: (data) => handleDeleteSubscription(data),
    },
  ];

  const subscriptionKeysLimitLabel =
    "Subscription keys limit reached. Delete an old key to request a new one.";
  const isSubscriptionKeysReachedLimit = dataList && dataList.length >= 3;

  const handleDeleteSubscription = (data: SubscriptionKeysItem) => {
    removeSubscriptionKey(data.DisplayName);
  };

  const TableContainer = () => {
    return (
      <DynamicTable
        title={labels.SUBSCRIPTION_KEYS}
        emptyTableMessage="Generate a new key to view the code."
        columns={columns}
        data={dataList}
      >
        {isLoadingDelete || isLoadingGenerate || isFetchingList ? (
          <SpinnerContainer />
        ) : (
          <Button
            buttonText={labels.GENERATE_KEY}
            onClick={createKey}
            disabled={isSubscriptionKeysReachedLimit}
            type={ButtonType.PRIMARY}
            ariaLabel="Generate key action"
          />
        )}
      </DynamicTable>
    );
  };

  const SpinnerContainer = () => {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner size="small" />
      </div>
    );
  };

  const calculateModalContent = () => {
    if (error) {
      if ("data" in error) {
        const errorDetails = error.data as ApiError;

        return (
          <ErrorBanner isVisible={true} errorCode={errorDetails.errorCode}>
            {errorDetails.errorDescription}
          </ErrorBanner>
        );
      }
    }

    if (isLoadingList || isFetchingList) return <SpinnerContainer />;

    if (dataList) return <TableContainer />;
  };

  return (
    <Modal
      title="Share Subscription Keys"
      description={
        isSubscriptionKeysReachedLimit ? subscriptionKeysLimitLabel : ""
      }
      status={statusModal}
      handleModalStatus={toggle}
      popoverText="This feature in SDK Builder is related to ST OpenCMSIS Project. This functionality allows the user to create his own secrets to acces OpenCMSIS API Authentication."
    >
      {calculateModalContent()}
    </Modal>
  );
};
